import { Box, Typography } from "@mui/material";
import { SplitButton } from "components/DesignSystem/Button";
import PageContainer from "views/dashboard/PageContainer";

const TicketingLanding = () => {
    const handleLogin = () => {
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "http://13.235.218.220:8118/login";
        form.target = '_blank'
        form.style.display = "none";

        // Add username and password fields
        const usernameField = document.createElement("input");
        usernameField.type = "hidden";
        usernameField.name = "login-username";
        usernameField.value = "Reviewer"; // Replace with actual username

        const passwordField = document.createElement("input");
        passwordField.type = "hidden";
        passwordField.name = "login-password";
        passwordField.value = "Gwd#2024"; // Replace with actual password

        form.appendChild(usernameField);
        form.appendChild(passwordField);

        document.body.appendChild(form);
        form.submit();
    };

    return (
        <PageContainer>
            <Box>
                <Typography variant="title1">Ticketing</Typography>
                <Box sx={{ marginTop: '32px', display: 'flex', gap: '16px', alignItems: 'center' }}>
                    <Typography variant="heading1">Click Here to go to Support</Typography>
                    <SplitButton onClick={handleLogin} >
                        <Typography variant="smallText">
                            Go to Dashboard
                        </Typography>
                    </SplitButton>
                </Box>
            </Box>
        </PageContainer>
    );
};

export default TicketingLanding;