/**
 * @module Components/DateInput
 *
 * @description `DateInput` is a React component that allows users to input a date in the format `DD-MM-YYYY`. The input is split into three fields: Day, Month, and * Year, which are validated individually. The component provides feedback when invalid input is detected, and it can be used with form libraries like * Formik to manage form state.
 *
 * @component
 */
import { Box, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import InputField from './DesignSystem/InputField';

const inputStyle = (sx) => ({
    '& .MuiInputBase-input': {
        textAlign: 'center', // Center text inside the input field
    },
    '& .MuiOutlinedInput-root': {
        ...sx,
    },
});

// Helper functions for validation
/**
 * Helper function to validate the day input.
 * @function isValidDay
 * @param {string} day - The day value to validate.
 * @returns {boolean} - Returns true if the day is valid (between 1 and 31).
 */
const isValidDay = (day) => /^\d{1,2}$/.test(day) && day >= 1 && day <= 31;

/**
 * Helper function to validate the month input.
 * @function isValidMonth
 * @param {string} month - The month value to validate.
 * @returns {boolean} - Returns true if the month is valid (between 1 and 12).
 */
const isValidMonth = (month) => /^\d{1,2}$/.test(month) && month >= 1 && month <= 12;

/**
 * Helper function to validate the year input.
 * @function isValidYear
 * @param {string} year - The year value to validate.
 * @returns {boolean} - Returns true if the year is valid (4 digits).
 */
const isValidYear = (year) => /^\d{1,4}$/.test(year);

/**
 * DateInput component renders three input fields for day, month, and year.
 * It also handles validation, error display, and formatting the date in `YYYY-MM-DD` format.
 *
 * @function DateInput
 *
 * @param {Object} props - The component props.
 * @param {string|null} [props.value] - The initial value of the date in `YYYY-MM-DD` format.
 * @param {Function} [props.onChange] - Callback function triggered when the date value changes. It should receive the date string `YYYY-MM-DD` or `null` if cleared.
 * @param {Function} [props.onBlur] - Callback function triggered when any input field loses focus.
 * @param {string} [props.helperText] - Helper text to display below the input fields (for error or instructions).
 * @param {boolean} [props.error=false] - If `true`, displays the error state.
 * @param {string} [props.label] - The label text to be shown above the input fields.
 * @param {Object} [props.sx={}] - The styling object that can be used to override default styles.
 * @param {string} [props.name] - The name attribute for the input fields, useful for form handling.
 * @param {boolean} [props.required=false] - If `true`, indicates the date input is required and adds a `*` to the placeholder.
 * @param {boolean} [props.disabled=false] - If `true`, disables the input fields.
 *
 * @returns {JSX.Element} - A `Box` component containing three input fields (day, month, year) for entering the date.
 */
function DateInput({
    value,
    onChange,
    onBlur,
    helperText,
    error,
    label,
    sx = {},
    name,
    required = false,
    disabled,
    ...props
}) {
    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);
    const theme = useTheme();

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [focusedField, setFocusedField] = useState(null);
    const [validationError, setValidationError] = useState('');

    // Update internal state when the value prop changes (e.g., when formik.resetForm() is called)
    useEffect(() => {
        if (value) {
            const [y, m, d] = value.split('-');
            setDay(d || '');
            setMonth(m || '');
            setYear(y || '');
        } else {
            setDay('');
            setMonth('');
            setYear('');
        }
    }, [value]);

    const notifyChange = (d = day, m = month, y = year) => {
        if (onChange) {
            if (d || m || y) {
                onChange(`${y}-${m}-${d}`);
            } else {
                onChange(null);
            }
        }
    };

    const handleDayChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setDay(value);
            if (value.length === 2) {
                monthRef.current.focus();
            }
            notifyChange(value, month, year);
        }
    };

    const handleMonthChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setMonth(value);
            if (value.length === 2) {
                yearRef.current.focus();
            } else if (value.length === 0) {
                dayRef.current.focus();
            }
            notifyChange(day, value, year);
        }
    };

    const handleYearChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setYear(value);
            notifyChange(day, month, value);
        }

        if (value === '') {
            setYear('');
            if (value.length === 0) {
                monthRef.current.focus();
            }
            if (focusedField === 'year') {
                notifyChange(day, month, '');
            }
        }
    };

    const handleBlur = () => {
        if (onBlur) {
            onBlur({
                target: {
                    name,
                },
            });
        }
    };

    useEffect(() => {
        if (!isValidDay(day) || !isValidMonth(month) || !isValidYear(year)) {
            setValidationError('Please enter a valid date.');
        } else {
            setValidationError('');
        }
    }, [day, month, year]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Box mr={2}>
                    <Typography variant="bodyText3">{label}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', minWidth: '240px' }}>
                    <Box>
                        <InputField
                            inputRef={dayRef}
                            placeholder={`DD${required ? '*' : ''}`}
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 2 }}
                            value={day}
                            onChange={handleDayChange}
                            onFocus={() => setFocusedField('day')}
                            onBlur={handleBlur}
                            error={error}
                            sx={inputStyle(sx)}
                            disabled={disabled}
                        />
                    </Box>
                    <Box>
                        <Typography variant="bodyText3" sx={{ color: theme.palette.grey[300], mx: '4px' }}>
                            -
                        </Typography>
                    </Box>
                    <Box>
                        <InputField
                            inputRef={monthRef}
                            placeholder={`MM${required ? '*' : ''}`}
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 2 }}
                            value={month}
                            onChange={handleMonthChange}
                            onFocus={() => setFocusedField('month')}
                            onBlur={handleBlur}
                            error={error}
                            sx={inputStyle(sx)}
                            disabled={disabled}
                        />
                    </Box>
                    <Box>
                        <Typography variant="bodyText3" sx={{ color: theme.palette.grey[300], mx: '4px' }}>
                            -
                        </Typography>
                    </Box>
                    <Box>
                        <InputField
                            inputRef={yearRef}
                            placeholder={`YYYY${required ? '*' : ''}`}
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            value={year}
                            onChange={handleYearChange}
                            onFocus={() => setFocusedField('year')}
                            onBlur={handleBlur}
                            error={error}
                            sx={{
                                ...inputStyle(),
                                '& .MuiOutlinedInput-root': {
                                    padding: '10px 8px', // Add padding specifically to the year input
                                    ...sx,
                                },
                            }}
                            disabled={disabled}
                        />
                    </Box>
                </Box>
            </Box>
            {error && (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', mr: 2 }}>
                    <Typography variant="errorText" sx={{ mt: 0.5 }}>
                        {helperText}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default DateInput;
